import * as React from "react"

import useStoryblok from "../../src/lib/storyblok"
import ContactPageLayout from "../components/layout/ContactPageLayout"
import StoryblokHelmet from "../components/internal/StoryblokHelmet"

const ContactPage = ({ pageContext, location }) => {
  let story = pageContext.story
  story = useStoryblok(story, location)
  const { meta } = story.content

  let storyData = story.story ? story.story.content : story.content

  if(meta && !meta.title) { 
    meta.title = story.name
  }

  return (
    <React.Fragment>
      <StoryblokHelmet meta={meta} />
      <ContactPageLayout
        pagePath={location.pathname}
        backgroundColor={storyData.background_color}
        breadcrumbAdditionalLabel={storyData.breadcrumb_additional_label}
        contactData={storyData.contact}
        contactContent={storyData.contact_content}
        mainContent={storyData.main_content}
        lowerContent={storyData.lower_content} />
    </React.Fragment>
  )
}

export default ContactPage